
import { Mixins, Component, Ref } from "vue-property-decorator";
import { Form } from "element-ui/types";
import loading from "@/mixins/loading";
import { getDeviceType, payloadParsing } from "@/api/tools";

@Component({
    components: {  }
})
export default class instructionParsing extends Mixins(loading) {
    @Ref("validateForm") formRef: Form;
    queryForm = {
        deviceType: "",
        payloadType: "",
        payload: ""
    };
    rules = {
        deviceType: [
            { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        payloadType: [
            { required: true, message: "请选择指令类型", trigger: "change" },
        ],
    };
    deviceTypeList = [];
    payloadTypeList = [
        { label: "下行：模块->主板", value: "false" },
        { label: "上行：主板->模块", value: "true" },
    ];
    result = "";


    mounted() {
    }

    getDeviceType() {
        getDeviceType().then((res) => {
            this.deviceTypeList = res.data;
        })
    }

    searchData() {
        this.formRef?.validate((valid) => {
            if (valid) {
                payloadParsing(this.queryForm).then((res) => {
                    this.result = res.data || ""
                })
            } else {
                return false;
            }
        })
    }
}

